<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_reservationIndividual" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.supplierRequired]"
                :error-messages="alertSupplierMessage"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 輸送会社名 -->
            <div class="search-textbox">
              <v-text-field
                outlined
                dense
                v-model="truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                readonly
                append-icon="mdi-close-circle"
                @click:append="append(), (truckNm = '')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- トラックボタン -->
            <div>
              <v-btn color="primary" class="api-btn" v-on:click="openTruck">
                {{ $t("btn.btn_addShpAdress") }}
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-single"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <!-- 品質区分-->
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 納品日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryDate"
                :label="$t('label.lbl_delDate')"
                @change="changeDeliveryDateTo"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.yyyymmdd_delivery]"
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuDeliveryDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateToCal"
                  @input="dateMenuDeliveryDate = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDeliveryDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDeliveryDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" v-if="disableReserve">&nbsp;</span>

            <!-- 引当基準-->
            <div class="search-autocomplete" v-if="disableReserve">
              <v-autocomplete
                dense
                id="get-reservation"
                v-model="reserveBasisSelected"
                :items="reserveBasisList"
                :label="$t('label.lbl_reserveBasis')"
                class="txt-single"
                :hint="setReserveBasis()"
                persistent-hint
                :error-messages="alertReserveMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" v-if="disableReserve">*</span>
            <!-- 空白 -->
            <span class="item-spacer" v-if="disableReserve">&nbsp;</span>

            <!-- ピッキング-->
            <div class="search-autocomplete" v-if="disableReserve">
              <v-autocomplete
                dense
                id="get-picking"
                v-model="pickingSelected"
                :items="pickingList"
                :label="$t('label.lbl_picking')"
                class="txt-single"
                :hint="setPicking()"
                persistent-hint
                :error-messages="alertPickingMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" v-if="disableReserve">*</span>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="btnSearch()">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--引当ボタン-->
                <v-btn class="post-btn" :disabled="disableBtn" @click="openReservationAdd()">{{
                  $t("btn.btn_reservation")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" v-if="disableReserve">
              <!--検索結果表示-->
              <div class="text-label">
                <span class="toatlReserve">引当合計：{{ this.totalCheckCount }}件</span>
                <span class="limitReserve">上限数：{{ this.ckeckLimit }}件</span>
                <span class="toatlShip">合計：{{ this.totalShipScheduleCount }}</span>
                <span class="toatlDetail">合計：{{ this.totalDetailCount }}</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :server-items-length="itemsPerPage"
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 明細数 -->
          <template v-slot:[`header.detailQty`]="{ header }">
            <div id="detailQtyWidth">{{ header.text }}</div>
          </template>

          <!-- 出荷数 -->
          <template v-slot:[`header.shipQty`]="{ header }">
            <div id="shipQtyWidth">{{ header.text }}</div>
          </template>

          <!-- 出荷No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <!-- 輸送会社 -->
          <template v-slot:[`item.truck`]="{ item }">
            <span v-if="item.truck == '路線'">{{ item.deliveryTraderName }}</span>
            <span v-else>{{ item.truck }}</span>
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <!-- トラック検索モーダル -->
      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDgialog.sync="isOpenTruck"
          ref="truck"
          @click="saveTruck"
          :truckInfo="truckInfo"
        />
      </v-dialog>

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>{{
            $t("label.lbl_alert")
          }}</v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Truck from "../common/Truck";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_STK_004,
  components: {
    Loading,
    ConfirmDialog,
    sideMenu,
    Truck,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ソート
    sortItem: "",
    sortOptions: {},
    // ページング
    itemsPerPage: -1,
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // チェック済みリスト
    checkedList: [],
    // 引当合計
    totalCheckCount: 0,
    // 上限数
    ckeckLimit: 0,
    // 明細数の合計
    totalDetailCount: 0,
    // 出荷数の合計
    totalShipScheduleCount: 0,
    // 輸送会社データ項目
    truckInfo: {
      suppliersSelected: "",
      truckInfo: "",
    },
    // 出荷予定日
    shippingScheduleDate: "",
    // 出荷予定カレンダー
    shippingScheduleDateCal: "",
    // 納品日
    deliveryDate: "",
    deliveryDateToCal: "",
    // 出荷先
    shippingSelected: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 品質区分
    qualitySelected: "",
    // 品質区分リスト
    qualityList: [],
    // 引当基準
    reserveBasisSelected: "",
    // ピッキング
    pickingSelected: "",
    // 引当基準リスト
    reserveBasisList: [],
    alertReserveMessage: "",
    // ピッキングリスト
    pickingList: [],
    alertPickingMessage: "",
    dateMenu: false,
    // 納品日カレンダー
    dateMenuDeliveryDate: false,
    // ボタンの表示切り替え
    disableBtn: true,
    // 引当方法の非表示
    disableReserve: false,
    // 検索条件保持
    searchParam: {
      // 取引先
      searchSuppliersSelected: "",
      // 出荷予定日
      searchShippingScheduleDate: "",
      // 出荷予定日カレンダー
      searchShippingScheduleDateCal: "",
      // 輸送会社
      searchTruckNm: "",
      searchTruckKbn: "",
      searchTruck: "",
      // 出荷先
      searchShippingSelected: "",
      searchShippingNm: "",
      // 出荷先リスト
      searchShippingList: "",
      // 品質区分
      searchQualitySelected: "",
      // 納品日
      searchDeliveryDate: "",
      // 引当基準
      searchReserveBasisSelected: "",
      // ピッキング区分
      searchPickingSelected: "",
    },
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 警告ダイアログ
    alertDialog: false,
    // 警告メッセージ
    checkMessage: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // 取引先エラーメッセージ
    alertSupplierMessage: "",
    // 遷移フラグ
    transitionFlg: false,
    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "4%",
        align: "center",
        sortable: false,
      },
      // 出荷日
      {
        text: i18n.tc("label.lbl_scheduleDate"),
        value: "shippingScheduleDateJ",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipNo",
        width: "13%",
        align: "left",
        sortable: true,
      },
      // 有償/無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 納品日
      {
        text: i18n.tc("label.lbl_delDate"),
        value: "deliveryDate",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 処理区分
      {
        text: i18n.tc("label.lbl_processingdivision"),
        value: "processingdivision",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_destination"),
        value: "shippingNm",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 輸送会社名
      {
        text: i18n.tc("label.lbl_shippingCompanyName"),
        value: "truck",
        align: "left",
        width: "12%",
        sortable: true,
      },
      // 号車
      {
        text: i18n.tc("label.lbl_shipTruckNum"),
        value: "transportVehicleCd",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 明細数
      {
        text: i18n.tc("label.lbl_unDetailQty"),
        value: "detailQty",
        width: "9%",
        align: "right",
        sortable: true,
      },
      // 出荷数
      {
        text: i18n.tc("label.lbl_unShipQty"),
        value: "shipQty",
        width: "9%",
        align: "right",
        sortable: true,
      },
    ],
    // 一覧
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 輸送会社名
    truckNm: "",
    // 輸送会社区分
    truckKbn: "",
    // 輸送会社名
    truck: "",
    // 入力チェック
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmdd_delivery: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
    // 出荷先取得用
    productHintArrival: "",
    getSuppliersSelected: "",
    // 検索時の出荷元
    shippingSelectedSearchCondition: "",
    // 検索時の出荷元リスト
    shippingListSearchCondition: [],
  }),

  created() {},

  methods: {
    append() {
      this.truckKbn = "";
    },

    /**
     * 初期値
     */
    init() {
      if (this.$route.params.name == appConfig.SCREEN_ID.P_STK_005) {
        // 遷移元画面の排他削除
        this.executeDeleteExclusiveReserve();

        // 引当登録画面から遷移の場合
        this.searchParam = this.$route.params.searchParam;
        this.suppliersSelected = this.searchParam.searchSuppliersSelected;
        this.shippingScheduleDate = this.searchParam.searchShippingScheduleDate;
        this.shippingScheduleDateCal = this.searchParam.searchShippingScheduleDateCal;
        this.truckNm = this.searchParam.searchTruckNm;
        this.truckKbn = this.searchParam.searchTruckKbn;
        this.truck = this.searchParam.searchTruck;
        this.shippingSelected = this.searchParam.searchShippingSelected;
        this.productHintArrival = this.searchParam.searchShippingNm
          ? this.searchParam.searchShippingNm
          : "";
        this.shippingSelectedSearchCondition = this.searchParam.shippingSelected;
        this.qualitySelected = this.searchParam.searchQualitySelected;
        this.reserveBasisSelected = this.searchParam.searchReserveBasisSelected;
        this.pickingSelected = this.searchParam.searchPickingSelected;
        let shippingList = this.searchParam.searchShippingList;
        this.shippingListSearchCondition = this.shippingList;
        this.shippingList = shippingList ? shippingList : [];
        if (this.searchParam.searchDeliveryDate) {
          this.deliveryDate = this.searchParam.searchDeliveryDate;
        }
        this.transitionFlg = true;
        this.btnSearch();
      } else {
        this.disableBtn = true;
        this.shippingScheduleDate = dateTimeHelper.convertJST();
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getCustomInfo();
      this.getMstCode();
      this.getLimitReservation();
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBizAuthority(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(),
        "1"
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 引当基準
      const reservationList = getParameter.getCodeMst(appConfig.CODETYPE.ALLOCATION_STANDARD);
      // ピッキング
      const pickingTypeList = getParameter.getCodeMst(appConfig.CODETYPE.PICKING_TYPE);

      Promise.all([qualityList, reservationList, pickingTypeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.qualityList = result[0];
          this.reserveBasisList = result[1];
          this.pickingList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 引当上限
     */
    getLimitReservation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.isMst = "0";
      config.params.codeType = appConfig.CODETYPE.LIMIT_RESERVATION;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  value: row.codeInfoList[0].item01,
                });
              });

              // 引当上限値
              this.ckeckLimit = list[0].value;

              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
            resolve(response);
          })
          .catch((resolve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 排他削除
     */
    executeDeleteExclusiveReserve() {
      try {
        return commonFunction.exclusiveReserve(
          appConfig.SCREEN_ID.P_STK_001,
          appConfig.EXCLUSIVE_RESERVE_PROC_DIV.DESTROY,
          "",
          ""
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      if (!this.truckInfo.carrierCompName && this.truckInfo.carrierCompCd == "04") {
        this.truckNm = i18n.tc("label.lbl_route");
      } else {
        this.truckNm = this.truckInfo.carrierCompName;
      }
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.truckName = this.truckInfo.deliveryTraderName;
      this.isOpenTruck = false;
    },

    /**
     * 引当検索
     */
    btnSearch() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      this.alertSupplierMessage = "";

      // 遷移時
      if (this.transitionFlg) {
        // 入力チェック無し
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェック
        inputCheckResult = this.$refs.form.validate();
      }

      if (inputCheckResult) {
        this.shippingSelectedSearchCondition = this.shippingSelected;
        this.shippingListSearchCondition =
          this.shippingSelected == "" || this.shippingSelected == null
            ? []
            : new Array(...this.shippingList);

        // 検索条件を保存
        this.searchParam.searchSuppliersSelected = this.suppliersSelected; // 取引先
        this.searchParam.searchShippingScheduleDate = this.shippingScheduleDate; // 出荷予定日
        this.searchParam.searchShippingScheduleDateCal = this.shippingScheduleDateCal; // 出荷予定日カレンダー
        this.searchParam.searchTruckNm = this.truckNm; // 輸送会社
        this.searchParam.searchTruckKbn = this.truckKbn;
        this.searchParam.searchTruck = this.truck;
        this.searchParam.searchShippingSelected = this.shippingSelectedSearchCondition; // 出荷先
        this.searchParam.searchShippingNm = this.productHintArrival;
        this.searchParam.searchShippingList = this.shippingListSearchCondition; // 出荷先リスト
        this.searchParam.searchQualitySelected = this.qualitySelected; // 品質区分
        this.searchParam.searchDeliveryDate = this.deliveryDate; // 納品日

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();
        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        config.params.clientSid = this.suppliersSelected;
        // 出荷予定日
        config.params.outScheduleDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
        // 品質区分
        if (this.qualitySelected) {
          config.params.qualityDiv = this.qualitySelected;
        }
        // 納品日
        if (this.deliveryDate) {
          config.params.deliveryDate = dateTimeHelper.convertUTC(
            this.deliveryDate + " 23:59:59.999"
          );
        }
        // 出荷先
        if (this.shippingSelected) {
          // ToSID（出荷先SID）
          config.params.toSid = this.shippingSelected;
        }
        // 路線業者SID（輸送会社名)
        if (this.truckKbn != "") {
          config.params.carrierCompCd = this.truckKbn;
          if (this.truck != "") {
            config.params.deliveryTraderSid = this.truck;
          }
        }

        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_ALLOCATIONSEARCH, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                const list = [];
                // 引当一覧リストを取得
                jsonData.resIdv.allocationList.forEach((row) => {
                  list.push({
                    // 出荷日（API登録用=UTC）
                    shippingScheduleDate: row.outScheduleDate,
                    // 出荷日（画面表示用=JST:秒数なし）
                    shippingScheduleDateJ: dateTimeHelper
                      .convertUTC2JST(row.outScheduleDate)
                      .substr(0, 10),
                    // 出荷SID
                    shipSid: row.outListSid,
                    // 出荷No
                    shipNo: row.outListNo,
                    // 有償/無償
                    isPaid: row.freeCostDivName,
                    // 有償無償名
                    freeCostDivName: row.freeCostDivName,
                    // 納品日
                    deliveryDate: row.deliveryDate,
                    // 処理区分名
                    processingdivision: row.processDivName,
                    // 出荷先
                    shippingNm: row.toName,
                    // 出荷先SID
                    toSid: row.toSid,
                    // 輸送会社名
                    truck: row.carrierCompCdName,
                    // 輸送会社区分
                    carrierCompCd: row.carrierCompCd,
                    // 輸送会社種別名
                    carrierCompName: row.carrierCompName,
                    // 号車
                    transportVehicleCd: row.transportVehicleCd,
                    // 明細数
                    detailQty: row.totalDetailCnt,
                    // 出荷数
                    shipQty: row.totalShipCnt,
                    // 路線業者SID
                    traderSid: row.deliveryTraderSid,
                    // 路線業者名
                    deliveryTraderName: row.deliveryTraderName,
                    // チェックボックス
                    check: false,
                    // 取引先SID
                    clientSid: row.clientSid,
                  });
                });
                this.inputList = list;
                this.itemsPerPage = this.inputList.length;
                this.screenSet();

                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 画面表示の設定
     */
    screenSet() {
      // 引当一覧リストの件数が0件でない場合
      if (this.inputList.length != 0) {
        this.disableReserve = true;
        this.disableBtn = false;
        this.getSize();
        // 遷移時
        if (this.transitionFlg) {
          this.checkedListInfo = this.$route.params.checkList;
          // 全選択の場合
          if (this.inputList.length == this.checkedListInfo.length) {
            this.isAllSelected = false;
            this.selectAllCheck();
            // 全選択でない場合
          } else {
            // チェックリストを取得
            for (var k = 0; k < this.inputList.length; k++) {
              for (var j = 0; j < this.checkedListInfo.length; j++) {
                if (this.inputList[k].shipSid == this.checkedListInfo[j]) {
                  this.inputList[k].check = true;
                }
              }
            }
            // 引当数合計
            this.totalCheckCount = this.checkedListInfo.length;
            // 合計値の取得
            for (var l = 0; l < this.checkedListInfo.length; l++) {
              for (var m = 0; m < this.inputList.length; m++) {
                if (this.checkedListInfo[l] == this.inputList[m].shipSid) {
                  // 明細数合計
                  this.totalDetailCount += this.inputList[m].detailQty;
                  // 出荷数合計
                  this.totalShipScheduleCount += this.inputList[m].shipQty;
                }
              }
            }
          }
          this.transitionFlg = false;
        } else {
          // 合計値の初期化
          this.totalCheckCount = 0;
          this.totalDetailCount = 0;
          this.totalShipScheduleCount = 0;
          // 全選択チェックを初期化
          this.isAllSelected = false;
          this.selectAllCheck();
          this.listCheckboxBtn.length = 0;
        }
      } else {
        // 全選択チェックの初期化
        this.isAllSelected = false;
        // 引当方法の非活性
        this.disableReserve = false;
      }
    },

    /**
     * 幅の取得
     */
    getSize() {
      // 幅の取得(明細数)
      var elementDetailQty = document.getElementById("detailQtyWidth");
      var styleDetailQty = window.getComputedStyle(elementDetailQty.parentNode);
      this.totalDetailWidth = styleDetailQty.width;
      // 幅の設定
      document.documentElement.style.setProperty("--toatlDetailSize", this.totalDetailWidth);

      // 幅の取得(出荷数)
      var elementShipQty = document.getElementById("shipQtyWidth");
      var styleShipQty = window.getComputedStyle(elementShipQty.parentNode);
      this.totalShipQtyWidth = styleShipQty.width;
      // 幅の設定
      document.documentElement.style.setProperty("--toatlShipSize", this.totalShipQtyWidth);
    },

    /**
     * 引当登録画面に遷移
     */
    openReservationAdd() {
      this.alertReserveMessage = "";
      this.alertPickingMessage = "";
      // 引当基準、ピッキングの必須チェック
      if (
        (this.reserveBasisSelected == "" || this.reserveBasisSelected == null) &&
        (this.pickingSelected == "" || this.pickingSelected == null)
      ) {
        this.alertReserveMessage = i18n.tc("check.chk_inputReservationSelect");
        this.alertPickingMessage = i18n.tc("check.chk_inputPickingSelect");
        return;
        // 引当基準の必須チェック
      } else if (this.reserveBasisSelected == "" || this.reserveBasisSelected == null) {
        this.alertReserveMessage = i18n.tc("check.chk_inputReservationSelect");
        return;
        // ピッキングの必須チェック
      } else if (this.pickingSelected == "" || this.pickingSelected == null) {
        this.alertPickingMessage = i18n.tc("check.chk_inputPickingSelect");
        return;
      }
      // チェックした行
      const checkList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          checkList.push(this.inputList[i]);
        }
      }
      // １つもチェックされていない場合、画面遷移できない
      if (checkList.length == 0) {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
        // コードマスタの条件値以上チェックされている場合
      } else if (checkList.length > this.ckeckLimit) {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-STK-004_001_E", [this.ckeckLimit]);
        return;
      } else {
        // チェック済みリスト
        for (let i = 0; i < checkList.length; i++) {
          this.checkedList.push(checkList[i].shipSid);
        }
      }

      // 引当方法を保持
      this.searchParam.searchReserveBasisSelected = this.reserveBasisSelected; // 引当基準
      this.searchParam.searchPickingSelected = this.pickingSelected; // ピッキング区分

      // 画面遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_STK_005,
        params: {
          // 検索条件
          searchParam: this.searchParam,
          // チェックリスト
          checkList: this.checkedList,
        },
      });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      // 合計値の初期化
      this.totalCheckCount = 0;
      this.totalDetailCount = 0;
      this.totalShipScheduleCount = 0;
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);

          // 明細数合計
          this.totalDetailCount += this.listCheckboxBtn[i].detailQty;
          // 出荷数合計
          this.totalShipScheduleCount += this.listCheckboxBtn[i].shipQty;
        }
        // 引当数合計
        this.totalCheckCount = this.inputList.length;
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
        // 引当数合計
        this.totalCheckCount = 0;
        // 明細数合計
        this.totalDetailCount = 0;
        // 出荷数合計
        this.totalShipScheduleCount = 0;
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);

        // 引当数合計
        ++this.totalCheckCount;
        // 明細数合計
        this.totalDetailCount += item.detailQty;
        // 出荷数合計
        this.totalShipScheduleCount += item.shipQty;
      } else {
        // チェックを外した場合

        // 外したレコードの出荷SIDを取得
        let inputListOutlistNo = item.shipSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].shipSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        // 引当数合計
        --this.totalCheckCount;
        // 明細数合計
        this.totalDetailCount -= item.detailQty;
        // 出荷数合計
        this.totalShipScheduleCount -= item.shipQty;
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 配送先選択ボタン処理
     */
    openTruck() {
      if (this.suppliersSelected) {
        this.truckInfo.suppliersSelected = this.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.suppliersSelected = null;
      }
    },

    /**
     * 出荷予定日付を－１日します。
     */
    prevDate() {
      if (this.shippingScheduleDateCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateCal != toDate) {
        this.shippingScheduleDateCal = toDate;
      } else {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 出荷予定日付を＋１日します。
     */
    nextDate() {
      if (this.shippingScheduleDateCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateCal != toDate) {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 納品日Toに-1日する。
     */
    prevToDeliveryDate() {
      this.deliveryDateToCal = commonFunction.addDate(this.deliveryDateToCal, "");
    },
    /**
     * 納品日Toに+1日する。
     */
    nextToDeliveryDate() {
      this.deliveryDateToCal = commonFunction.addDate(this.deliveryDateToCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateCal = null;
      }
    },

    /**
     * 納品日
     */
    changeDeliveryDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.deliveryDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.deliveryDateToCal = null;
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      return commonFunction.getListSetName(this.shippingList, this.shippingSelected);
    },

    /**
     * 引当基準コードリストボックス取得処理
     */
    setReserveBasis() {
      for (var i = 0; i < this.reserveBasisList.length; i++) {
        if (this.reserveBasisList[i].value == this.reserveBasisSelected) {
          return this.reserveBasisList[i].name;
        }
      }
    },

    /**
     * ピッキングコードリストボックス取得処理
     */
    setPicking() {
      for (var i = 0; i < this.pickingList.length; i++) {
        if (this.pickingList[i].value == this.pickingSelected) {
          return this.pickingList[i].name;
        }
      }
    },

    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 変更検知用の設定
      this.shippingScheduleDateCal = "";
      // 初期値の設定
      this.suppliersSelected = "";
      this.shippingScheduleDate = dateTimeHelper.convertJST();
      this.shippingScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.truckNm = "";
      this.shippingSelected = "";
      this.qualitySelected = "";
      this.deliveryDate = "";
      this.reserveBasisSelected = "";
      this.pickingSelected = "";
      this.inputList = [];
      this.totalCheckCount = 0;
      this.totalDetailCount = 0;
      this.totalShipScheduleCount = 0;
      this.disableReserve = false;
      this.isAllSelected = false;
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected(newValue, oldValue) {
      // 出荷先リスト/輸送会社名クリア
      if (oldValue != null && oldValue != "") {
        this.shippingList = [];
        this.shippingSelected = "";
        this.truckNm = "";
        this.truckKbn = "";
        this.truck = "";
      }

      this.alertSupplierMessage = "";
    },

    shippingScheduleDateCal: {
      handler(val) {
        this.shippingScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    deliveryDateToCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_STK_004_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.btnSearch();
        }
      },
      deep: true,
    },
    // 出荷先取得
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.toatlReserve {
  margin-left: 10px;
  float: left;
}
.limitReserve {
  margin-left: 25px;
}
:root {
  --toatlDetailSize: 300px;
  --toatlShipSize: 200px;
}
.toatlDetail {
  width: var(--toatlDetailSize);
  float: right;
  text-align: center;
}
.toatlShip {
  width: var(--toatlShipSize);
  float: right;
  text-align: center;
}
#listData ::v-deep th #detailQtyWidth {
  display: inline-block;
}
#listData ::v-deep th #shipQtyWidth {
  display: inline-block;
}
</style>
